import { Injectable } from '@angular/core';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { PDFCapableComponent, PDFPosition, PDFFonts } from './pdf-interfaces';

@Injectable({
  providedIn: 'root'
})
export class PdfGenerationService {
  constructor() {}

  public addHeaderToPage(
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts,
    pageNumber?: number
  ): void {
    const { pageWidth, margin } = position;
    const headerY = position.pageHeight - 20;

    const companyName = formValues.carrierCompanyName || 'Company Name';
    const companyNameWidth = fonts.bold.widthOfTextAtSize(companyName, 12);
    const centerX = (pageWidth - companyNameWidth) / 2;

    page.drawText(companyName, {
      x: centerX,
      y: headerY,
      size: 12,
      font: fonts.bold
    });

    const dbaName = formValues.dbaName || '-';
    const dbaNameWidth = fonts.bold.widthOfTextAtSize(dbaName, 12);
    const dbaNameCenterX = (pageWidth - dbaNameWidth) / 2;

    page.drawText(dbaName, {
      x: dbaNameCenterX,
      y: headerY - 15,
      size: 12,
      font: fonts.bold
    });
 
    const companyAddress = formValues.carrierCompanyAddress || '';
    const companyAddressWidth = fonts.regular.widthOfTextAtSize(companyAddress, 10);
    const addressCenterX = (pageWidth - companyAddressWidth) / 2;

    page.drawText(companyAddress, {
      x: addressCenterX,
      y: headerY - 30,
      size: 10,
      font: fonts.regular
    });

    page.drawLine({
      start: { x: margin, y: headerY - 35 },
      end: { x: pageWidth - margin, y: headerY - 35 },
      thickness: 1,
      color: rgb(0.7, 0.7, 0.7)
    });

    position.headerOffset = headerY - 75;

    position.y = position.headerOffset;
  }

  async generatePDF(
    components: PDFCapableComponent[],
    formValues: any,
    filename: string = 'document.pdf',
    signatureMap: Map<string, string>
  ): Promise<Blob> {
    const loadingDiv = document.createElement('div');
    loadingDiv.style.position = 'fixed';
    loadingDiv.style.top = '0';
    loadingDiv.style.left = '0';
    loadingDiv.style.width = '100%';
    loadingDiv.style.height = '100%';
    loadingDiv.style.backgroundColor = 'rgba(0,0,0,0.7)';
    loadingDiv.style.zIndex = '9999';
    loadingDiv.style.display = 'flex';
    loadingDiv.style.justifyContent = 'center';
    loadingDiv.style.alignItems = 'center';

    loadingDiv.id = 'pdf-generation-loading';

    document.body.appendChild(loadingDiv);
    loadingDiv.innerHTML =
      '<div style="background: white; color: black; padding: 20px; border-radius: 5px;">Generating PDF... Please wait.</div>';

    try {
      console.time('PDF Generation');

      const pdfDoc = await PDFDocument.create();

      const fonts: PDFFonts = {
        regular: await pdfDoc.embedFont(StandardFonts.Helvetica),
        bold: await pdfDoc.embedFont(StandardFonts.HelveticaBold),
        italic: await pdfDoc.embedFont(StandardFonts.HelveticaOblique)
      };

      const signatures = new Map<string, any>();

      for (const [key, dataUrl] of signatureMap.entries()) {
        if (dataUrl) {
          const base64Data = dataUrl.split(',')[1];
          const signatureBytes = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
          signatures.set(key, await pdfDoc.embedPng(signatureBytes));
        }
      }

      const position: PDFPosition = {
        x: 40,
        y: 750,
        pageWidth: 612,
        pageHeight: 792,
        margin: 40,
        headerOffset: undefined
      };

      // Create first page
      let page = pdfDoc.addPage([position.pageWidth, position.pageHeight]);
      let pageCount = 1;

      // Add header to the first page
      this.addHeaderToPage(page, position, formValues, fonts, pageCount);

      for (let i = 0; i < components.length; i++) {
        loadingDiv.innerHTML = `<div style="background: white; color: black; padding: 20px; border-radius: 5px;">
          Generating PDF...<br>Processing component ${i + 1} of ${components.length}
        </div>`;

        const component = components[i];

        const enhancedFormValues = {
          ...formValues,
          signatures,
          pageCount,
          addHeaderToPage: this.addHeaderToPage.bind(this),
          currentPageCount: pageCount
        };

        // Render component to current page
        const result = await component.renderToPDF(pdfDoc, page, position, enhancedFormValues, fonts);

        // Update position
        if (result.newPage) {
          page = pdfDoc.getPage(pdfDoc.getPageCount() - 1);
          pageCount = enhancedFormValues.currentPageCount || pageCount + 1;
        } else {
          position.y -= result.height;

          if (position.y < position.margin * 2) {
            page = pdfDoc.addPage([position.pageWidth, position.pageHeight]);
            pageCount++;

            this.addHeaderToPage(page, position, formValues, fonts, pageCount);
          }
        }
      }

      const pageCount_total = pdfDoc.getPageCount();
      for (let i = 0; i < pageCount_total; i++) {
        const page = pdfDoc.getPage(i);
        page.drawText(`Page ${i + 1} of ${pageCount_total}`, {
          x: position.pageWidth / 2 - 30,
          y: 30,
          size: 10,
          font: fonts.regular
        });
      }

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      console.timeEnd('PDF Generation');
      return blob;
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('An error occurred while generating the PDF');
      throw error;
    } finally {
      const loadingElement = document.getElementById('pdf-generation-loading');
      if (loadingElement && loadingElement.parentNode) {
        loadingElement.parentNode.removeChild(loadingElement);
      }
    }
  }
}
