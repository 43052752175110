import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page22-confidentiality-policy',
  templateUrl: './page22-confidentiality-policy.component.html',
  styleUrls: ['../shared.scss', './page22-confidentiality-policy.component.scss']
})
export class Page22ConfidentialityPolicyComponent implements OnInit {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;
  @Input() carrierCompanyNameCtrl: FormControl;

  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;

  signaturePad: SignaturePad;

  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.initializeSignaturePad();
  }

  initializeSignaturePad() {
    if (this.signatureCanvas) {
      this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }
  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Create first page
    let currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
    }
    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading
    const headingText = 'Confidentiality Policy';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw first paragraph with inline fields

    const introText = 'In consideration of the foregoing, I ';
    const driverName = formValues.driverName || '';
    const midText = ' hereby agree to the following terms of "Confidentiality" with continuing my employment with ';
    const companyName = formValues.carrierCompanyName || '';

    const segments = [
      { text: introText, font: fonts.regular },
      { text: driverName, font: fonts.bold },
      { text: midText, font: fonts.regular },
      { text: companyName + '.', font: fonts.bold }
    ];

    // draw and wrap nicely
    currentY = this.drawWrappedSegments(currentPage, segments, x, currentY, {
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });

    currentY -= 10; // spacing after paragraph

    // Indent the bulleted policy points
    const indentedX = x + 20;
    const indentedMaxWidth = maxWidth - 20;

    // First policy point
    const policy1Start = 'That I shall not use or disclose, without consent of ';
    currentPage.drawText(policy1Start, {
      x: indentedX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of policy1Start
    const policy1StartWidth = fonts.regular.widthOfTextAtSize(policy1Start, 12);

    // Add company name
    currentPage.drawText(companyName, {
      x: indentedX + policy1StartWidth,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Move to next line for rest of text
    currentY -= 20;

    // Continue with next part - start a new line to prevent overflow
    const policy1Mid = 'any trade secrets, confidential proprietary information of or concerning ';
    currentPage.drawText(policy1Mid, {
      x: indentedX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of policy1Mid
    const policy1MidWidth = fonts.regular.widthOfTextAtSize(policy1Mid, 12);

    // Add company name again
    currentPage.drawText(companyName, {
      x: indentedX + policy1MidWidth,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Move to next line for final part
    currentY -= 20;
    currentPage.drawText("'s affiliates, clients, suppliers or personnel.", {
      x: indentedX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentY -= 25;

    // Second policy point
    const policy2Start = 'That I shall refrain from any conduct or statements inimical to the best interests of ';
    currentPage.drawText(policy2Start, {
      x: indentedX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of policy2Start
    const policy2StartWidth = fonts.regular.widthOfTextAtSize(policy2Start, 12);

    // Add company name
    currentPage.drawText(companyName, {
      x: indentedX + policy2StartWidth,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Move to next line for second part
    currentY -= 20;

    // Continue with next part
    const policy2Mid = 'and particularly, shall make no adverse or unfavorable public statements concerning ';
    currentPage.drawText(policy2Mid, {
      x: indentedX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of policy2Mid
    const policy2MidWidth = fonts.regular.widthOfTextAtSize(policy2Mid, 12);

    // Add company name again
    currentPage.drawText(companyName, {
      x: indentedX + policy2MidWidth,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Move to next line for final part
    currentY -= 20;
    currentPage.drawText('or my relationship with it.', {
      x: indentedX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentY -= 25;

    // Third policy point
    const policy3Start = 'That I shall return all property of ';
    currentPage.drawText(policy3Start, {
      x: indentedX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of policy3Start
    const policy3StartWidth = fonts.regular.widthOfTextAtSize(policy3Start, 12);

    // Add company name
    currentPage.drawText(companyName, {
      x: indentedX + policy3StartWidth,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Move to next line for second part
    currentY -= 20;

    // Split the text into smaller chunks to avoid overflow
    const policy3MidPart1 = 'and all records and copies of records of the Company dealing with';
    currentPage.drawText(policy3MidPart1, {
      x: indentedX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Move to next line for the next part
    currentY -= 20;

    const policy3MidPart2 = 'the operations and activities of ';
    currentPage.drawText(policy3MidPart2, {
      x: indentedX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of policy3MidPart2
    const policy3MidPart2Width = fonts.regular.widthOfTextAtSize(policy3MidPart2, 12);

    // Add company name again
    currentPage.drawText(companyName, {
      x: indentedX + policy3MidPart2Width,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Calculate width of company name
    const companyWidth = fonts.bold.widthOfTextAtSize(companyName, 12);

    // Check if remaining text will fit
    const finalText = ', affiliates, clients, suppliers or personnel.';
    const finalTextWidth = fonts.regular.widthOfTextAtSize(finalText, 12);

    if (policy3MidPart2Width + companyWidth + finalTextWidth > indentedMaxWidth) {
      // Move to next line for final part if it won't fit
      currentY -= 20;
      currentPage.drawText(finalText, {
        x: indentedX,
        y: currentY,
        size: 12,
        font: fonts.regular
      });
    } else {
      // Add on same line if it fits
      currentPage.drawText(finalText, {
        x: indentedX + policy3MidPart2Width + companyWidth,
        y: currentY,
        size: 12,
        font: fonts.regular
      });
    }

    currentY -= 25;

    // Fourth policy point
    const policy4Start = 'I understand that all information and happenings within ';
    currentPage.drawText(policy4Start, {
      x: indentedX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of policy4Start
    const policy4StartWidth = fonts.regular.widthOfTextAtSize(policy4Start, 12);

    // Add company name
    currentPage.drawText(companyName, {
      x: indentedX + policy4StartWidth,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Move to next line for continuation
    currentY -= 20;

    // Continue with next part on new line to avoid overflow
    const policy4End =
      'are to be kept strictly confidential and that any breach of the above will be subject to disciplinary action up to and including termination of employment.';
    currentY = this.drawWrappedText(currentPage, policy4End, indentedX, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: indentedMaxWidth
    });

    currentY -= 25;

    // Fifth policy point - Split into multiple lines to avoid any overflow
    currentPage.drawText('I understand that all information is private and is legally protected by law,', {
      x: indentedX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Move to next line for continuation
    currentY -= 20;

    currentPage.drawText('as well as Company Policy of', {
      x: indentedX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of the text
    const companyPolicyWidth = fonts.regular.widthOfTextAtSize('as well as Company Policy of ', 12);

    // Add company name on the same line with proper spacing
    currentPage.drawText(' ' + companyName + '.', {
      x: indentedX + companyPolicyWidth,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentY -= 40;

    // Check if we need a new page for signature
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    const signature = formValues.signatures?.get('driverSignaturePage22');
    if (signature) {
      currentPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    currentPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
  drawWrappedSegments(page, segments, x, y, { fontSize, lineHeight, maxWidth }) {
    let currentX = x;
    let currentY = y;

    for (const segment of segments) {
      const words = segment.text.split(' ');

      for (const word of words) {
        const wordWithSpace = word + ' ';
        const wordWidth = segment.font.widthOfTextAtSize(wordWithSpace, fontSize);

        if (currentX + wordWidth > x + maxWidth) {
          currentY -= lineHeight;
          currentX = x;
        }

        page.drawText(wordWithSpace, {
          x: currentX,
          y: currentY,
          size: fontSize,
          font: segment.font
        });

        currentX += wordWidth;
      }
    }

    return currentY - lineHeight; // return next Y position
  }
}
