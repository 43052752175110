import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ROUTE_DQF,
  ROUTE_DQF_ABSTRACT,
  ROUTE_DQF_BACKGROUND_CHECK_PROCESSING,
  ROUTE_DQF_DECLARATION_SIGN,
  ROUTE_DQF_DOCUMENT_UPLOAD,
  ROUTE_DQF_ONTARIO_CVOR_ABSTRACT,
  ROUTE_DQF_POLICE_CERTIFICATE,
  ROUTE_DQF_PRE_EMPLOYMENT_CERTIFICATE,
  ROUTE_DQF_SIGN_DECLARATION
} from 'src/app/shared/routes';
import { DocumentAction } from '../dqf-document-upload/dqf-document-upload.component';
import { AttachedDocuments } from '../models/attachedDocuments';
import { CarrierAttachedDocuments } from '../models/carrierAttachedDocuments';
import { DqfDocument } from '../models/dqfDocument';
import { DriverQualificationService } from '../service/driver-qualification.service';
import {
  DRUG_ALCOHOL_TEST,
  ROAD_TEST,
  DRUG_ALOCHOL_CLEANING_HOUSE,
  EMPLOYMENT_HISTORY_CHECK,
  DRIVER_TRAINING_TESTS,
  DQF_WSIB_CLEARANCE,
  DQF_OTHER_RELATED_DOC
} from '../constants';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { License } from 'src/app/shared/models/license.model';
import { Location } from '@angular/common';
const STATUS_REJECTED = 'Rejected';
const STATUS_PENDING = 'Pending';
const DOC_STATUS_DRIVER_REVIEWED = 'DriverReviewed';
const DRIVER_UPLOADS = 'Driver Uploads';
const CARRIER_UPLOADS = 'Carrier Uploads';
const UPDATE_DQF_DOC_MODEL = {
  attachedDocument: {
    type: '',
    status: DOC_STATUS_DRIVER_REVIEWED
  }
};

@Component({
  selector: 'app-dqf-upload',
  templateUrl: './dqf-upload.component.html',
  styleUrls: ['./dqf-upload.component.scss']
})
export class DqfUploadComponent implements OnInit {
  heading = null;
  documents: DocumentAction[] = [];
  showStatus = false;
  currentStatus = null;
  currentSelectedData: DocumentAction | string = null;
  order: number;
  licenseProvince = null;
  driverType: string;
  carrierCompanyId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private driverQualificationService: DriverQualificationService,
    private router: Router,
    private registrationService: RegistrationService,
    private driverApiService: DriverApiService,
    private readonly location: Location
  ) {}

  ngOnInit(): void {
    if (!this.driverQualificationService.dqfId) {
      this.router.navigate([`${ROUTE_DQF}`]);
      return;
    }

    this.getLicense();

    this.activatedRoute.queryParams.subscribe(params => {
      this.heading = params.type;
    });
  }

  getDqf(): void {
    this.driverQualificationService
      .getDqfById(this.driverQualificationService.dqfId)
      .subscribe((response: DqfDocument) => {
        if (response) {
          this.carrierCompanyId = response?.carrierCompanyId;
          this.bindDqfResponse(response);
        }
      });
  }
  backAction(): void {
    this.router.navigateByUrl(`${ROUTE_DQF}/${ROUTE_DQF_DOCUMENT_UPLOAD}/${this.driverQualificationService.dqfId}`);
  }

  closeNotification(): void {
    this.showStatus = !this.showStatus;
    this.currentSelectedData = null;
  }

  private navigateToDocument(doc: DocumentAction, callback: Function): void {
    if (doc.status == STATUS_REJECTED) {
      const sheetData = {
        displayName: doc.displayName,
        reason: doc.rejectionReason,
        type: doc.type
      };

      UPDATE_DQF_DOC_MODEL.attachedDocument.type = doc.type;
      this.driverQualificationService.openBottomSheetWithFunction(sheetData, UPDATE_DQF_DOC_MODEL, () => callback());
    } else {
      callback();
    }
  }

  private bindDqfResponse(response: DqfDocument): void {
    if (this.heading === DRIVER_UPLOADS && response?.attachedDocuments?.length) {
      this.documents = this.bindDriverAttachments(response.attachedDocuments);
    } else if (this.heading === CARRIER_UPLOADS && response?.carrierAttachedDocuments?.length) {
      this.documents = this.bindCarrierAttachments(response.carrierAttachedDocuments);
    }
    this.documents.sort((a, b) => a.order - b.order);
  }

  private bindDriverAttachments(attachedDocuments: AttachedDocuments[]): DocumentAction[] {
    const driver = this.registrationService.getDriver();
    const companyDriver = driver?.companyAssociations?.find(d => d.id.includes(this.carrierCompanyId));
    const driverType = driver?.companyDriverType || undefined;
    this.driverType = companyDriver ? companyDriver.driverType : driverType;
    const documents: DocumentAction[] = this.driverQualificationService.bindDriverAttachments(
      attachedDocuments,
      this.licenseProvince,
      this.driverType,
      this.navigateToDocument.bind(this),
      this.navigateToSignDeclaration.bind(this),
      this.navigateToAbstractDoc.bind(this),
      this.navigateToPoliceCertificateBasedOnAction.bind(this),
      this.navigateToPreEmploymentScreeningReport.bind(this),
      this.navigateToEmploymentHistoryCheck.bind(this),
      this.navigateToOntarioCvorAbstractDoc.bind(this)
    );

    return documents;
  }

  private bindCarrierAttachments(attachedDocuments: CarrierAttachedDocuments[]): DocumentAction[] {
    const documents: DocumentAction[] = [];
    attachedDocuments.forEach(doc => {
      let info: DocumentAction = {
        status: !doc.id ? STATUS_PENDING : DOC_STATUS_DRIVER_REVIEWED,
        type: doc?.type,
        isRequirementsCompleted: !doc.id ? false : true,
        id: doc.id || null
      };
      if (info.type === DRUG_ALCOHOL_TEST) {
        info.iconName = 'flask-icon';
        info.displayName = 'Drug Test Report';
        info.clickHandler = () => {
          this.bindShowStatus(info);
        };
        info.order = 1;
        documents.push(info);
        return;
      }
      if (info.type === ROAD_TEST) {
        info.iconName = 'steer-icon';
        info.displayName = 'Road Test Report';
        info.clickHandler = () => {
          this.bindShowStatus(info);
        };
        info.order = 2;
        documents.push(info);
        return;
      }
      if (info.type === DRUG_ALOCHOL_CLEANING_HOUSE) {
        info.iconName = 'health-icon';
        info.displayName = 'Drug and Alcohol Clearing House report';
        info.clickHandler = () => {
          this.bindShowStatus(info);
        };
        info.order = 3;
        documents.push(info);
        return;
      }
      if (info.type === DRIVER_TRAINING_TESTS) {
        info.iconName = 'emp-history-icon';
        info.displayName = 'Driver Training Tests report';
        info.clickHandler = () => {
          this.bindShowStatus(info);
        };
        info.order = 4;
        documents.push(info);
        return;
      }
      if (info.type === DQF_WSIB_CLEARANCE) {
        info.iconName = 'emp-history-icon';
        info.displayName = 'WSIB Clearance Report';
        info.clickHandler = () => {
          this.bindShowStatus(info);
        };
        info.order = 5;
        documents.push(info);
        return;
      }
      if (info.type === DQF_OTHER_RELATED_DOC) {
        info.iconName = 'emp-history-icon';
        info.displayName = 'Other Related Document';
        info.clickHandler = () => {
          this.bindShowStatus(info);
        };
        info.order = 6;
        documents.push(info);
        return;
      }
    });

    return documents;
  }

  //Driver
  private navigateToOntarioCvorAbstractDoc() {
    this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_ONTARIO_CVOR_ABSTRACT}`]);
  }
  private navigateToAbstractDoc() {
    this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_ABSTRACT}`]);
  }
  private navigateToPoliceClearanceCertificate() {
    this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_POLICE_CERTIFICATE}`]);
  }
  private navigateToBackgroundProcessingRequest(documentType: string) {
    this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_BACKGROUND_CHECK_PROCESSING}`], {
      queryParams: {
        documentType
      }
    });
  }
  private navigateToPreEmploymentScreeningReport(): void {
    this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_PRE_EMPLOYMENT_CERTIFICATE}`]);
  }

  private navigateToSignDeclaration(): void { 
    // uncomment to enable hello Sign
     this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_SIGN_DECLARATION}`]);

   // this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_DECLARATION_SIGN}`]);
  }
  private navigateToEmploymentHistoryCheck(): void {
    this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_SIGN_DECLARATION}`], {
      queryParams: { type: EMPLOYMENT_HISTORY_CHECK }
    });
  }
  private navigateToPoliceCertificateBasedOnAction(info: any) {
    this.navigateToDocument(info, () => {
      if (info['action'] && info['action'] == 'collect') {
        this.navigateToBackgroundProcessingRequest('POLICE CLEARANCE');
      } else {
        this.navigateToPoliceClearanceCertificate();
      }
    });
  }

  // Carrier
  private bindShowStatus(data: DocumentAction): void {
    this.showStatus = true;
    this.currentSelectedData = data;
    if (!data.id) {
      this.currentStatus = `${data.displayName} not uploaded`;
      return;
    }
    this.currentStatus = `Carrier has uploaded the ${data.displayName}`;
  }

  private getLicense(): void {
    const license = this.registrationService.getLicenseStore();
    if (license) {
      this.licenseProvince = license.issuingProvince?.toLowerCase();
    }
    this.getDqf();
  }
}
