import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page14-accident-policy',
  templateUrl: './page14-accident-policy.component.html',
  styleUrls: ['../shared.scss', './page14-accident-policy.component.scss']
})
export class Page14AccidentPolicyComponent implements OnInit, PDFCapableComponent {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;
  constructor() {}

  ngOnInit(): void {}
  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Create first page
    let currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
    }
    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading
    const headingText = 'Accident Policy';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw intro paragraph
    const introParagraph =
      'In spite of our best efforts to avoid accidents, we realize they can happen and we must be prepared when they ' +
      'do. If you are involved in a vehicle accident, do the following: -';

    currentY = this.drawWrappedText(currentPage, introParagraph, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    // Draw the bullet points
    const bulletPoints = [
      'Stop immediately.',
      'If you can safely do so, move out of the way of traffic.',
      'Check other drivers and passengers for injuries.',
      'If you are not injured, protect the accident scene to prevent other vehicles from becoming involved. Put out ' +
        'emergency reflectors or flares.',
      'Call for help and the police if necessary.',
      'Notify your supervisor or manager.',
      'Take photographs of the accident scene, if possible, with the disposable camera kept in the Accident Kit ' +
        'located in the vehicle.',
      'Complete the Drivers Report in the Accident Kit.',
      'Hand out witness cards and get names, addresses, and telephone numbers of witnesses.',
      'Do not admit liability at the accident scene.'
    ];

    // Draw each bullet point
    for (const point of bulletPoints) {
      // Check if we need a new page
      if (currentY < margin + 100) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
        currentY = pageHeight - margin;
      }

      // Draw bullet
      currentPage.drawText('•', {
        x,
        y: currentY,
        size: 12,
        font: fonts.regular
      });

      // Draw bullet point text with indentation
      currentY = this.drawWrappedText(currentPage, point, x + 15, currentY, {
        font: fonts.regular,
        fontSize: 12,
        lineHeight: 16,
        maxWidth: maxWidth - 15
      });
      currentY -= 10; // Space between items
    }

    // Check if we need a new page for following paragraphs
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw first concluding paragraph
    const concludingParagraph1 =
      'In many cases, determining fault for an accident is done only after extensive investigation. Be courteous to ' +
      'others at the scene, but never take the blame. Let the investigators determine that. Also, do not discuss the ' +
      'details of the accident with anyone other than a licensed authority such as a police officer or our insurance ' +
      'company representative, without getting prior approval from management.';

    currentY = this.drawWrappedText(currentPage, concludingParagraph1, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 15;

    // Draw second concluding paragraph
    const concludingParagraph2 = 'Upon returning to the dock, submit the completed Accident Kit to your supervisor.';

    currentY = this.drawWrappedText(currentPage, concludingParagraph2, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    // Check if we need a new page for signature
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    // Draw date field
    currentPage.drawText('Date:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.dateOfApplication || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for date
    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}
