import { Component, ElementRef, NgZone, OnInit, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { CANADA_PROVINCES, CERTN_COUNTRIES, USA_PROVINCES } from 'src/app/shared/models/provinces';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@tabuckner/material-dayjs-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { ResidenceAddress } from '../shared/models/residence-adress.model';
import { catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import dayjs, { Dayjs } from 'dayjs';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { of } from 'rxjs';
import { Driver } from 'src/app/shared/models/driver.model';
import { ROUTE_DQF, ROUTE_RESIDENCE_HISTORY, ROUTE_ADD_RESIDENCE_HISTORY } from 'src/app/shared/routes';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { RequirementsCompleteActionComponent } from '../requirements-complete-action/requirements-complete-action.component';
import { Router } from '@angular/router';

class AddressModel {
  fullAddress: string;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
const REQUIRED_FIELD_MESSAGE = 'You must enter a value';
const MAX_LENGTH_ERROR_MESSAGE = 'Please enter correct value';
const SAVE_RESIDENCE_HISTORY = 'Save Residence history';
const PATTERN_ERROR_MESSAGE = 'Only letters, spaces and hyphens are acceptable';
const MaximumLengthFieldMessage = (value: number) => `Must be less than ${value} characters`;
const REGEX_LETTERS_SPACES_HYPHENS = '^[A-Za-z- ]*$';
const country_US = 'US';
const country_CA = 'CA';
@Component({
  selector: 'app-residence-history',
  templateUrl: './residence-history.component.html',
  styleUrls: ['./residence-history.component.scss'],
  providers: [
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class ResidenceHistoryComponent implements OnInit {
  isContentReady: boolean = false;
  addressModel: AddressModel;
  canadaProvinces = CANADA_PROVINCES;
  usaProvinces = USA_PROVINCES;
  form: FormGroup;
  previousAddressForm: FormGroup;
  lastDateOfMonth = dayjs()
    .endOf('month')
    .valueOf();
  currentDate = new Date(this.lastDateOfMonth);
  maxDate = dayjs(this.currentDate);
  minDate = dayjs(this.currentDate).subtract(15, 'year');
  maxFromDate = this.maxDate;
  minFromDate = this.minDate;
  currentMonthYear = dayjs(this.currentDate).format('MMMM YYYY');
  lastDate = dayjs(this.currentDate).subtract(3, 'year');
  lastMonthYear = dayjs(this.lastDate).format('MMMM YYYY');
  progressBarValue: number = 100;
  addresses: ResidenceAddress[] = [];
  isCurrentAddressEdit: boolean = false;
  isPreviousAddress: boolean = false;
  isLessThan3years: boolean = false;
  isEditAddress: boolean = false;
  isNewCurrentAddressAdd: boolean = false;
  isCurrentAddressNotPresent: boolean = false;
  isAddingCurrentAddress: boolean = false;
  errorMessage: string;
  editFormTitle: string = 'Current Address';
  currentIndex: number = 0;
  turnOffAddressLookup: boolean = false;
  countries: any;
  certnCountries = CERTN_COUNTRIES;
  countryUSorCA: string[] = [country_US, country_CA];
  driver: Driver;
  showAddNewCurrentText: boolean;
  isCurrentAddressValid: boolean = false;
  isGoogleAddressLookupEnabled: boolean = true; // Default value for toggle
  autocompleteInstances = new Map<string, google.maps.places.Autocomplete>(); // To track autocomplete instances
  @ViewChild('currentAddress', { read: ViewContainerRef }) currentTemplateForm: ViewContainerRef;
  @ViewChild('previousAddress', { read: ViewContainerRef }) previousTemplateForm: ViewContainerRef;
  @ViewChildren('currentToDatePicker') toDateList;
  @ViewChildren('fromCurrentAddressPicker') fromDateList;
  @ViewChildren('fromPreviousAddressPicker') fromPreviousDateList;
  @ViewChildren('previousAddressToDatePicker') toPreviousDateList;
  @ViewChild('addressInput', { static: false }) addressInput!: ElementRef | undefined;
  private initialized = false;
  constructor(
    private readonly registrationService: RegistrationService,
    private readonly location: Location,
    private readonly zone: NgZone,
    private readonly fb: FormBuilder,
    private toastService: ToastService,
    private readonly bottomSheet: MatBottomSheet,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.form = this.createForm();
    this.previousAddressForm = this.createPreviousAddressForm();
    this.loadAllAddresses();
    this.certnCountries = this.certnCountries.map(country => {
      if (!this.countryUSorCA.includes(country.value)) {
        country.value = country.key;
      } else if (country.value === country_US) {
        country.key = 'USA';
      }
      return country;
    }).filter((country, index, self) =>
      index === self.findIndex(c => c.value === country.value)
    );
    this.countries = this.certnCountries;
  }

  ngAfterViewInit(): void {
    if (this.isGoogleAddressLookupEnabled) {
      setTimeout(() => {
        if (this.addressInput?.nativeElement) {
          this.loadGoogleAutoComplete(this.addressInput.nativeElement);
        } 
      });
    }
  }

  goBack(): void {
    this.location.back();
  }

  loadAllAddresses(): void {
    this.isContentReady = false;
    this.registrationService.getAllAddress().subscribe(res => {
      this.addresses = res;
      const currentResidence = this.addresses.find(e => e.isCurrentAddress);
      if (this.addresses.length) {
        this.addresses.sort((a, b) => new Date(b.livedFromDate).getTime() - new Date(a.livedFromDate).getTime());
        this.checkMissingGap();
        // if there is no currentResidence in addresses
        if (this.addresses.length > 0 && !currentResidence) {
          this.progressBarValue = 90;
        }
        // for berbix flow - when currentAddress doesnt have fromDate
        this.checkIfCurrentAddressIsUpdate();
      } else {
        this.isLessThan3years = false;
        this.progressBarValue = 100;
        this.isCurrentAddressNotPresent = true;
      }
      this.registrationService.loadDriver().subscribe(() => {
        this.driver = this.registrationService.getDriver();
        if (this.location.path().includes(ROUTE_DQF)) {
          if (
            !this.driver.residenceHistoryRequirementsCompleted &&
            currentResidence &&
            dayjs(currentResidence?.modifiedDate)
              .set('date', 1)
              .format('YYYY MM') <
            dayjs(this.currentDate)
              .set('date', 1)
              .format('YYYY MM')
          ) {
            this.openRequirementsActionSheet();
            this.progressBarValue = 90;
          } else {
            // after requirements stage user refreshed the page
            if (!this.driver.residenceHistoryRequirementsCompleted && this.addresses.length && !currentResidence) {
              this.showAddNewCurrentText = true;
            }
          }
        }
        this.isContentReady = true;
      });
    });
  }

  checkIfCurrentAddressIsUpdate(): void {
    if (this.addresses && this.addresses.length > 0) {
      this.isCurrentAddressNotPresent = false;
      let isCurrentAddress = this.addresses.find(el => el.isCurrentAddress);
      if (isCurrentAddress && this.addresses.length === 1) {
        if (isCurrentAddress.livedFromDate == '' || !isCurrentAddress.hasOwnProperty('livedFromDate')) {
          this.isCurrentAddressEdit = true;
          this.isCurrentAddressValid = false;
          this.populateForm(isCurrentAddress);
        } else {
          this.isCurrentAddressValid = true;
        }
      } else {
        this.isCurrentAddressValid = true;
      }
    } else {
      this.isCurrentAddressNotPresent = true;
    }
  }

  openRequirementsActionSheet() {
    let currentAddress: ResidenceAddress = this.addresses.find(emp => emp.isCurrentAddress == true);
    if (!currentAddress) {
      return;
    }
    const bottomSheetRef = this.bottomSheet.open(RequirementsCompleteActionComponent, {
      panelClass: 'signature-panel',
      data: {
        currentAddress: currentAddress,
        isEmploymentHistory: false
      },
      disableClose: true
    });
    bottomSheetRef.afterDismissed().subscribe(data => {
      if (data.type === 'yes') {
        this.isContentReady = false;
        let addressModel = { ...currentAddress };
        let id = addressModel.id;
        delete addressModel.id;
        delete addressModel.modifiedDate;
        delete addressModel.creationDate;
        delete addressModel.version;
        delete addressModel.status;
        delete addressModel.livedToDate;
        this.updateAddress(addressModel, id);
      } else {
        // when user selects toDate
        this.isContentReady = false;
        let addressModel = { ...currentAddress };
        addressModel.livedToDate = dayjs(data.data?.toDate)
          .set('date', 1)
          .format('YYYY-MM-DD');
        addressModel.isCurrentAddress = false;
        let id = addressModel.id;
        delete addressModel.id;
        delete addressModel.creationDate;
        delete addressModel.version;
        delete addressModel.status;
        delete addressModel.modifiedDate;
        this.updateAddress(addressModel, id);
      }
    });
  }

  chosenMonthHandler(normalizedMonth: Dayjs, datepicker: MatDatepicker<Dayjs>, element?: string): void {
    const date = dayjs()
      .set('month', normalizedMonth.get('month'))
      .set('year', normalizedMonth.get('year'))
      .set('date', 1);
    const formControl = new FormControl(date);
    if (element) {
      if (element == 'previous') {
        this.previousAddressForm.patchValue({
          previousAddressFromDate: formControl.value
        });
      }
      if (element == 'toDateControl') {
        this.previousAddressForm.patchValue({
          previousAddressToDate: formControl.value
        });
      }

      if (element == 'fromCurrentAddressPicker') {
        this.form.patchValue({
          fromDate: formControl.value
        });
      }

      if (element == 'currentToDatePicker') {
        this.form.patchValue({
          toDate: formControl.value
        });
      }
    }
    datepicker.close();
  }

  get addressId() {
    return this.form.get('id');
  }
  get streetAddress() {
    return this.form.get('streetAddress');
  }
  get city() {
    return this.form.get('city');
  }
  get state() {
    return this.form.get('state');
  }
  get country() {
    return this.form.get('country');
  }
  get zipCode() {
    return this.form.get('zipCode');
  }
  get toDate() {
    return this.form.get('toDate');
  }
  get fromDate() {
    return this.form.get('fromDate');
  }
  get completeAddress() {
    return this.form.get('completeAddress');
  }
  get fullAddress() {
    return this.previousAddressForm.get('fullAddress');
  }
  get previousAddress() {
    return this.previousAddressForm.get('previousAddress');
  }
  get previousAddressCity() {
    return this.previousAddressForm.get('previousAddressCity');
  }
  get previousAddressState() {
    return this.previousAddressForm.get('previousAddressState');
  }
  get previousAddressCountry() {
    return this.previousAddressForm.get('previousAddressCountry');
  }
  get previousAddressZipCode() {
    return this.previousAddressForm.get('previousAddressZipCode');
  }
  get previousAddressFromDate() {
    return this.previousAddressForm.get('previousAddressFromDate');
  }
  get previousAddressToDate() {
    return this.previousAddressForm.get('previousAddressToDate');
  }

  getStreetAddressErrorMessage(): string {
    return this.streetAddress.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getCityAddressErrorMessage(): string {
    return this.city.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getStateAddressErrorMessage(): string {
    return this.state.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getCountryErrorMessage(): string {
    return this.country.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getZipcodeErrorMessage(): string {
    return this.zipCode.errors.required
      ? REQUIRED_FIELD_MESSAGE
      : this.zipCode.errors.maxLength
        ? MAX_LENGTH_ERROR_MESSAGE
        : '';
  }
  getFromDateErrorMessage(): string {
    return this.fromDate.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getToDateErrorMessage(): string {
    return this.toDate.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getFullAddressErrorMessage(): string {
    return this.fullAddress.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getPreviousAddressErrorMessage(): string {
    return this.previousAddress.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getPreviousAddressCityErrorMessage(): string {
    const errors = this.previousAddressCity.errors;
    return errors.required
      ? REQUIRED_FIELD_MESSAGE
      : errors.pattern
        ? PATTERN_ERROR_MESSAGE
        : errors.maxlength
          ? MaximumLengthFieldMessage(errors.maxlength.requiredLength)
          : '';
  }
  getPreviousAddressStateErrorMessage(): string {
    const errors = this.previousAddressState.errors;
    return errors.required
      ? REQUIRED_FIELD_MESSAGE
      : errors.pattern
        ? PATTERN_ERROR_MESSAGE
        : errors.maxlength
          ? MaximumLengthFieldMessage(errors.maxlength.requiredLength)
          : '';
  }
  getPreviousAddressCountryErrorMessage(): string {
    const errors = this.previousAddressCountry.errors;
    return errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getPreviousAddressZipCodeErrorMessage(): string {
    return this.previousAddressZipCode.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getPreviousAddressFromDate(): string {
    return this.previousAddressFromDate.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getPreviousAddressToDate(): string {
    return this.previousAddressToDate.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  addPreviousAddress(index: number): void {
    if (this.isCurrentAddressValid) {
      let toDate = null;
      this.currentIndex = index;
      this.isPreviousAddress = true;
      this.isCurrentAddressEdit = false;
      this.previousAddressForm = this.createPreviousAddressForm();
      this.form = this.createForm();
      if (index > 0) {
        if (!this.addresses[index]?.livedFromDate) {
          this.minFromDate = dayjs(this.addresses[index + 1].livedToDate);
          this.maxFromDate = dayjs(this.addresses[index - 1].livedFromDate);
          this.previousAddressFromDate.setValue(dayjs(this.addresses[index + 1].livedToDate));
        } else {
          this.minFromDate = this.minDate;
          this.maxFromDate = this.maxDate;
        }
        toDate = dayjs(this.addresses[index - 1].livedFromDate);
      }
      if (index < 0) {
        toDate = dayjs(this.addresses[this.addresses.length - 1].livedFromDate);
      }
      this.previousAddressToDate.setValue(toDate);
      const dynamicPreviousAddressId = document.querySelector('#previousAddressText' + index) as HTMLInputElement;
      this.getPlaceAutocomplete(dynamicPreviousAddressId, true);
      this.countries = this.filterCountryDropdown();
    }
  }

  cancelPreviousAddressForm(): void {
    this.resetMaxAndMinValues();
    this.isPreviousAddress = false;
    this.isCurrentAddressEdit = false;
    this.turnOffAddressLookup = false;
    this.resetPreviousAddressValidation();
    this.form = this.createForm();
    this.previousAddressForm = this.createPreviousAddressForm();
  }

  onCancelCurrentAddress(): void {
    this.resetMaxAndMinValues();
    this.isCurrentAddressEdit = false;
    this.isPreviousAddress = false;
    this.form = this.createForm();
    this.previousAddressForm = this.createPreviousAddressForm();
  }

  resetMaxAndMinValues(): void {
    this.currentTemplateForm?.clear();
    this.previousTemplateForm?.clear();
    this.minFromDate = this.minDate;
    this.maxFromDate = this.maxDate;
  }

  editIconClicked(address: ResidenceAddress, index: number): void {
    this.currentIndex = index;
    this.isPreviousAddress = false;
    this.isAddingCurrentAddress = false;
    this.populateForm(address);
    if (!address?.isCurrentAddress) {
      this.editFormTitle = 'Previous Address';
    } else {
      this.isAddingCurrentAddress = true;
      this.editFormTitle = 'Current Address';
    }
    this.isCurrentAddressEdit = true;
    this.loadGoogleAutoComplete(index);
    this.countries = this.filterCountryDropdown();
  }

  onAddNewCurrentAddressText() {
    let minFromDate = dayjs(this.addresses[0].livedToDate);
    this.onAddNewCurrentAddress(true, -1, minFromDate);
  }

  onAddNewCurrentAddress(isAddingCurrentAddress: boolean, index: number, minFromDate?: any): void {
    this.currentIndex = index;
    this.editFormTitle = 'Current Address';
    this.form = this.createForm();
    this.previousAddressForm = this.createPreviousAddressForm();
    this.isAddingCurrentAddress = isAddingCurrentAddress;
    if (isAddingCurrentAddress) {
      this.form.get('toDate').patchValue(this.currentDate);
    }
    this.isCurrentAddressEdit = true;
    this.loadGoogleAutoComplete(index);
    const currentAddress = this.addresses.find(address => address.isCurrentAddress === true);

    if (minFromDate) {
      this.minFromDate = minFromDate;
    } else {
      this.minFromDate = currentAddress?.livedFromDate
        ? dayjs(currentAddress.livedFromDate).add(1, 'month')
        : this.minDate;
    }

    this.maxFromDate = this.maxDate;
    this.countries = this.filterCountryDropdown();
  }

  onCurrentAddressSave(): void {
    if (!this.form.valid) {
      return;
    }
    let model = this.prepareSaveModel();
    if (model && model.id) {
      let id = model.id;
      delete model.id;
      if (model.isCurrentAddress) {
        delete model.livedToDate;
      }
      this.updateAddress(model, id);
    } else if (model) {
      if (
        this.isAddingCurrentAddress &&
        !this.showAddNewCurrentText &&
        this.addresses.some(address => address.isCurrentAddress == true)
      ) {
        let currentAddress: ResidenceAddress = this.addresses.find(address => address.isCurrentAddress == true);
        currentAddress.isCurrentAddress = false;
        currentAddress.livedToDate = model.livedFromDate;

        const id = currentAddress.id;
        delete currentAddress.id;
        delete currentAddress.status;
        delete currentAddress.modifiedDate;
        delete currentAddress.creationDate;
        delete currentAddress.version;

        this.updateAddress(currentAddress, id, model);
      } else {
        delete model.id;
        model.isCurrentAddress = true;
        delete model.livedToDate;
        this.addNewAddress(model, true);
      }
    }
  }

  onAddPreviousAddress(): void {
    if (!this.previousAddressForm.valid) {
      return;
    }
    let model = this.preparePreviousAddressModel();

    if (model) {
      this.isPreviousAddress = false;
      this.addNewAddress(model);
      this.turnOffAddressLookup = false;
      this.resetPreviousAddressValidation();
      this.form = this.createForm();
      this.previousAddressForm = this.createPreviousAddressForm();
    }
  }
  navigateToPreviousAddressForm(): void {
    this.router.navigateByUrl(`${ROUTE_RESIDENCE_HISTORY}/${ROUTE_ADD_RESIDENCE_HISTORY}`);
  }

  showHideAddButton() {
    if (this.isCurrentAddressEdit || this.isPreviousAddress || this.isLessThan3years) {
      return false;
    }
    return true;
  }

  private addNewAddress(model, updateShowAddNewCurrentText?: boolean): void {
    this.isContentReady = false;

    this.registrationService
      .addAddress(model)
      .pipe(
        catchError((error: any) => {
          this.isContentReady = true;
          this.toastService.showError(error?.message);
          return of(false);
        })
      )
      .subscribe(res => {
        if (res) {
          this.errorMessage = undefined;
          if (updateShowAddNewCurrentText) {
            this.showAddNewCurrentText = false;
          }
          this.isAddingCurrentAddress = false;
          this.loadAllAddresses();
          this.resetFlags();
        }
      });
  }
  private updateAddress(model: ResidenceAddress, id: string, newCurrentAddress?: ResidenceAddress): void {
    this.isContentReady = false;
    this.registrationService
      .updateAddress(model, id)
      .pipe(
        catchError((error: any) => {
          this.isContentReady = true;
          this.toastService.showError(error?.message);
          return of(false);
        })
      )
      .subscribe(res => {
        if (res) {
          this.errorMessage = undefined;
          if (this.isAddingCurrentAddress && newCurrentAddress) {
            this.isAddingCurrentAddress = false;
            newCurrentAddress.isCurrentAddress = true;

            delete newCurrentAddress.id;
            delete newCurrentAddress.livedToDate;
            this.addNewAddress(newCurrentAddress);
          } else {
            this.resetFlags();
            this.loadAllAddresses();
          }
        }
      });
  }

  countryLowerCaseCheck(country: string): string {
    return this.countryUSorCA.includes(country) ? country.toLowerCase() : country;
  }
  onDatePickerClick(datepicker: any) {
    datepicker.open();
  }
  private preparePreviousAddressModel(): ResidenceAddress {
    const formModel = this.previousAddressForm.value;
    const addressModel: AddressModel = this.parseAddress(formModel.fullAddress);
    const model: ResidenceAddress = {
      completeAddress: this.turnOffAddressLookup
        ? `${formModel.previousAddress}, ${formModel.previousAddressCity}, ${formModel.previousAddressState} ${formModel.previousAddressZipCode}, ${formModel.previousAddressCountry}`
        : formModel.fullAddress,
      streetAddress: this.turnOffAddressLookup ? formModel.previousAddress : addressModel.streetAddress,
      city: this.turnOffAddressLookup ? formModel.previousAddressCity : addressModel.city,
      province: this.turnOffAddressLookup ? formModel.previousAddressState : addressModel.state,
      country: this.countryLowerCaseCheck(
        this.turnOffAddressLookup ? formModel.previousAddressCountry : addressModel.country
      ),
      postalCode: this.turnOffAddressLookup ? formModel.previousAddressZipCode : addressModel.zipcode,
      livedFromDate: formModel.previousAddressFromDate
        ? (dayjs(formModel.previousAddressFromDate).format('YYYY-MM-DD') as string)
        : '',
      livedToDate: formModel.previousAddressToDate
        ? (dayjs(formModel.previousAddressToDate).format('YYYY-MM-DD') as string)
        : ''
    };
    if (formModel.id && formModel.id != '') {
      model.id = formModel.id;
    }
    return model;
  }

  private prepareSaveModel(): ResidenceAddress {
    const formModel = this.form.value;
    // Construct the complete address by combining relevant fields
    const completeAddress = [
      formModel.streetAddress,
      formModel.city,
      formModel.state,
      formModel.zipCode,
      this.countryLowerCaseCheck(formModel.country)
    ].filter(Boolean)
      .join(', ');      // Join with commas and spaces
    const model: ResidenceAddress = {
      completeAddress: completeAddress,
      streetAddress: formModel.streetAddress,
      city: formModel.city,
      province: formModel.state,
      country: this.countryLowerCaseCheck(formModel.country),
      postalCode: formModel.zipCode,
      livedFromDate: formModel.fromDate ? (dayjs(formModel.fromDate).format('YYYY-MM-DD') as string) : '',
      id: formModel.id ? formModel.id : null
    };
    if (formModel.toDate) {
      model.livedToDate = dayjs(formModel.toDate).format('YYYY-MM-DD') as string;
    }
    if (formModel.isCurrentAddress) {
      model.isCurrentAddress = formModel.isCurrentAddress;
    }
    return model;
  }

  private getPlaceAutocomplete(addressText: any, isPrevious: boolean): void {
    if (addressText) {
      const autocomplete = new google.maps.places.Autocomplete(addressText?.nativeElement || addressText, {
        componentRestrictions: {
          country: this.countryUSorCA
        },
        types: []
      });
      // Store the autocomplete instance for later use
      this.autocompleteInstances.set(addressText.id, autocomplete);
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        if (place) {
          this.zone.run(() => {
            if (isPrevious) {
              this.fullAddress.setValue(place.formatted_address);
            } else {
              const addressModel = this.parseAddress(place.formatted_address);
              this.assignAddressField(addressModel);
            }
          });
        }
      });
    }
  }

  private parseAddress(address: string): AddressModel {
    const parsedAddressArr = address?.split(',');
    const addressModel = new AddressModel();
    if (address && parsedAddressArr.length === 4) {
      addressModel.fullAddress = address;
      addressModel.streetAddress = parsedAddressArr[0]?.trim();
      addressModel.city = parsedAddressArr[1]?.trim();
      addressModel.state = parsedAddressArr[2]?.trim();
      addressModel.zipcode = addressModel.state.substring(3, addressModel.state.length)?.trim();
      addressModel.state = addressModel.state?.split(' ')[0];
      addressModel.country = parsedAddressArr[3]?.trim();
      if (addressModel.country == 'Canada' || addressModel.country == 'CA') {
        addressModel.country = 'CA';
      }
      if (addressModel.country == 'USA' || addressModel.country == 'US') {
        addressModel.country = 'US';
        addressModel.state = `US-${addressModel.state}`;
      }
    }
    return addressModel;
  }
  private createForm(): FormGroup {
    return this.fb.group({
      completeAddress: ['', Validators.required],
      streetAddress: ['', Validators.required],
      city: ['', [Validators.required, Validators.pattern("^[A-Za-z-'. ]*$"), Validators.maxLength(25)]],
      state: ['', [Validators.required, Validators.pattern(REGEX_LETTERS_SPACES_HYPHENS), Validators.maxLength(25)]],
      country: ['', Validators.required],
      zipCode: ['', Validators.required],
      toDate: ['', Validators.required],
      fromDate: ['', Validators.required],
      id: [''],
      isCurrentAddress: [false]
    });
  }

  private createPreviousAddressForm(): FormGroup {
    return this.fb.group({
      fullAddress: ['', Validators.required],
      previousAddress: [''],
      previousAddressCity: [''],
      previousAddressState: [''],
      previousAddressCountry: [''],
      previousAddressZipCode: [''],
      previousAddressFromDate: ['', Validators.required],
      previousAddressToDate: ['', Validators.required]
    });
  }

  private populateForm(address: ResidenceAddress): void {
    if (address) {
      this.assignAddressField({
        fullAddress: address.completeAddress,
        streetAddress: address.streetAddress,
        city: address.city,
        state: address.province,
        zipcode: address.postalCode,
        country: this.countryUSorCA.includes(address.country.toUpperCase())
          ? address.country.toUpperCase()
          : address.country
      });
      if (address?.livedToDate) {
        this.toDate.setValue(dayjs(address?.livedToDate));
      }
      if (address.livedFromDate) {
        this.fromDate.setValue(dayjs(address?.livedFromDate));
      }
      if (address.isCurrentAddress) {
        this.form.get('isCurrentAddress').setValue(address.isCurrentAddress);
        this.form.get('toDate').setValue(dayjs(this.currentDate));
      }
      this.addressId.setValue(address?.id);
    }
  }

  private assignAddressField(addressModel: AddressModel): void {
    if (!addressModel) {
      return;
    }
    this.completeAddress.setValue(addressModel.fullAddress);
    this.streetAddress.setValue(addressModel.streetAddress);
    this.city.setValue(addressModel.city);
    this.state.setValue(addressModel.state);
    this.country.setValue(addressModel.country);
    this.zipCode.setValue(addressModel.zipcode);
  }

  private resetFlags(): void {
    this.cancelPreviousAddressForm();
    this.onCancelCurrentAddress();
  }

  private checkMissingGap() {
    let isYearsInOrder = true;
    let addressData: ResidenceAddress[] = [...this.addresses];
    const currentAddress = this.addresses.filter(e => e.isCurrentAddress);
    let currentDiffAddressMonth = 0;
    const date1 = dayjs(this.currentDate);
    const date2 = dayjs(currentAddress[0]?.livedFromDate);
    currentDiffAddressMonth = Math.round(date1.diff(date2, 'month', true));

    if (this.addresses.length > 1) {
      let spliceIndex = 0;
      for (let index = 0; index < addressData.length - 1; index++) {
        if (addressData[index + 1] && addressData[index + 1].hasOwnProperty('livedToDate')) {
          if (
            new Date(addressData[index + 1].livedToDate).getFullYear() ===
            new Date(addressData[index].livedFromDate).getFullYear() &&
            new Date(addressData[index + 1].livedToDate).getMonth() ===
            new Date(addressData[index].livedFromDate).getMonth()
          ) {
            const date1 = dayjs(addressData[index + 1].livedToDate);
            const date2 = dayjs(addressData[index + 1].livedFromDate);
            currentDiffAddressMonth += Math.round(date1.diff(date2, 'month', true));

            if (isYearsInOrder) {
              this.updateProgressBar(currentDiffAddressMonth, isYearsInOrder);
            }
          } else {
            if (!spliceIndex) {
              spliceIndex = index + 1;
            } else {
              spliceIndex += index;
            }
            if (addressData.length - 1 === index + 1 && addressData.length - 2 === index) {
              this.addresses.splice(this.addresses.length - 1, 0, { livedFromDate: null } as ResidenceAddress);
            } else {
              this.addresses.splice(spliceIndex, 0, { livedFromDate: null } as ResidenceAddress);
            }
            if (index === 0) {
              this.updateProgressBar(currentDiffAddressMonth, isYearsInOrder);
              isYearsInOrder = this.checkIsYearsInOrder(currentDiffAddressMonth);
            } else {
              isYearsInOrder = this.checkIsYearsInOrder(currentDiffAddressMonth);
              this.updateProgressBar(currentDiffAddressMonth, isYearsInOrder);
            }
          }
        }
      }
    } else {
      this.checkAddressHasThreeYearInfo(currentDiffAddressMonth, isYearsInOrder);
      this.updateProgressBar(currentDiffAddressMonth, isYearsInOrder);
    }
  }

  private checkAddressHasThreeYearInfo(month: number, isYearsInOrder: boolean): boolean {
    if (month >= 36 && isYearsInOrder) {
      this.isLessThan3years = true;
      return true;
    }
    this.isLessThan3years = false;
    return false;
  }

  private updateProgressBar(currentDiffAddressMonth: number, isYearsInOrder: boolean): void {
    if (isYearsInOrder) {
      const percent = (currentDiffAddressMonth / 36) * 100;
      this.progressBarValue = percent >= 100 ? 0 : 100 - percent;
    }
  }

  private loadGoogleAutoComplete(index: number): void {
    if (this.isGoogleAddressLookupEnabled) {
      const dynamicCurrentAddressId = document.querySelector('#addressText' + index) as HTMLInputElement;
      if (dynamicCurrentAddressId) {
        this.getPlaceAutocomplete(dynamicCurrentAddressId, false);
      }
    }
  }

  private checkIsYearsInOrder(currentDiffAddressMonth) {
    if (currentDiffAddressMonth < 36) {
      return false;
    } else {
      return true;
    }
  }

  onCountryChange(formName, field): void {
    this[formName].patchValue({
      [field]: ''
    });
  }

  onCertnCountrySearch(event: any): void {
    const searchText = event?.target?.value?.trim();

    let countries = this.filterCountryDropdown();
    if (searchText) {
      this.countries = countries.filter(country => country.key.toLowerCase().includes(searchText.toLowerCase()));
    } else {
      this.countries = countries;
    }
  }

  filterCountryDropdown() {
    let countries = this.certnCountries;
    if (this.isAddingCurrentAddress && this.isCurrentAddressEdit) {
      countries = this.certnCountries.filter(country => this.countryUSorCA.includes(country.value));
    } else {
      countries = this.certnCountries;
    }
    return countries;
  }

  onTurnOffAddressAutoLookup() {
    this.turnOffAddressLookup = true;
    this.fullAddress.clearValidators();
    this.previousAddress.setValidators([Validators.required]);
    this.previousAddressCity.setValidators([
      Validators.required,
      Validators.pattern(REGEX_LETTERS_SPACES_HYPHENS),
      Validators.maxLength(25)
    ]);
    this.previousAddressState.setValidators([
      Validators.required,
      Validators.pattern(REGEX_LETTERS_SPACES_HYPHENS),
      Validators.maxLength(25)
    ]);
    this.previousAddressCountry.setValidators([Validators.required]);
    this.previousAddressZipCode.setValidators([Validators.required]);
    this.previousAddressForm.patchValue({
      fullAddress: ''
    });
  }

  resetPreviousAddressValidation() {
    this.fullAddress.setValidators([Validators.required]);
    this.previousAddress.clearValidators();
    this.previousAddressCity.clearValidators();
    this.previousAddressState.clearValidators();
    this.previousAddressCountry.clearValidators();
    this.previousAddressZipCode.clearValidators();
  }

  // Toggle Google Address Lookup
  onGoogleAddressLookupToggle(event: any, index: number): void {
    this.isGoogleAddressLookupEnabled = !this.isGoogleAddressLookupEnabled;
    // Dynamically query the input field by index
    const input = document.querySelector('#addressText' + index) as HTMLInputElement;
    if (!input) {
      console.warn('Address input field is not available in the DOM.');
      return;
    }
    if (!this.isGoogleAddressLookupEnabled) {
      // Remove required validator for completeAddress
      this.form.get('completeAddress').clearValidators();
      this.form.get('completeAddress').updateValueAndValidity();
      // If Google Address Lookup is disabled, clear the autocomplete instance
      const autocompleteInstance = this.autocompleteInstances.get(input.id);
      if (autocompleteInstance) {
        google.maps.event.clearInstanceListeners(input);
        this.autocompleteInstances.delete(input.id);  // Remove instance from the Map
      }
      // Allow manual entry by clearing the field or enabling it
      this.form.get('streetAddress').setValue('');
      this.form.get('country').setValue('');
      this.form.get('state').setValue('');
      this.form.get('city').setValue('');
      this.form.get('zipCode').setValue('');
    } else {
      // Enable Google Address Lookup, create new autocomplete instance
      this.loadGoogleAutoComplete(index);
    }
  }
}
